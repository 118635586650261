import React from 'react';

const Picked = (props) => {
  const { team, mode, bo, picked } = props.main;
  const { toggle_modal } = props.actionsModal;

  return (
    <div className="home__picked">
      <div className="home__picked__title">
        <img src={require('../assets/images/picked.png')} alt="picked" />
      </div>
      {bo === 5 && (
        <div className="home__picked__team">
          <p>{team.teamA}</p>
          <p>{team.teamB}</p>
        </div>
      )}
      <div className="home__picked__wrapper">
        <div className="home__picked__wrapper__column">
          <div className="home__picked__wrapper__column__icon">
            <img
              src={require(`../assets/images/icon-${mode.column_1}.png`)}
              alt="icon"
            />
          </div>
          <div
            className="home__picked__wrapper__column__picked"
            style={{
              backgroundImage: `url(${picked.column_1.teamA.img_banpick})`,
            }}
            onClick={() => toggle_modal('banpick', true, 'pick', 'column_1-a')}>
            <img
              className="home__picked__wrapper__column__picked__ribbon"
              src={require('../assets/images/Number_placeholder.png')}
              alt="ribbon-number"
            />
            <span>3</span>
          </div>
          {bo === 3 ? (
            <div
              className="home__picked__wrapper__column__picked"
              style={{
                cursor: 'default',
              }}>
              <p>{team.teamA}</p>
            </div>
          ) : (
            <div
              className="home__picked__wrapper__column__picked"
              style={{
                backgroundImage: `url(${picked.column_1.teamB.img_banpick})`,
              }}
              onClick={() =>
                toggle_modal('banpick', true, 'pick', 'column_1-b')
              }>
              <img
                className="home__picked__wrapper__column__picked__ribbon"
                src={require('../assets/images/Number_placeholder.png')}
                alt="ribbon-number"
              />
              <span>4</span>
            </div>
          )}
        </div>

        <div className="home__picked__wrapper__column">
          <div className="home__picked__wrapper__column__icon">
            <img
              src={require(`../assets/images/icon-${mode.column_2}.png`)}
              alt="icon"
            />
          </div>
          <div
            className="home__picked__wrapper__column__picked"
            style={{
              backgroundImage: `${
                bo === 3
                  ? `url(${picked.column_2.teamB.img_banpick})`
                  : `url(${picked.column_2.teamA.img_banpick})`
              } `,
            }}
            onClick={() =>
              toggle_modal(
                'banpick',
                true,
                'pick',
                bo === 3 ? 'column_2-b' : 'column_2-a'
              )
            }>
            <img
              className="home__picked__wrapper__column__picked__ribbon"
              src={require('../assets/images/Number_placeholder.png')}
              alt="ribbon-number"
            />
            <span>{bo === 3 ? 6 : 8}</span>
          </div>
          {bo === 3 ? (
            <div
              className="home__picked__wrapper__column__picked"
              style={{
                cursor: 'default',
              }}>
              <p>{team.teamB}</p>
            </div>
          ) : (
            <div
              className="home__picked__wrapper__column__picked"
              style={{
                backgroundImage: `url(${picked.column_2.teamB.img_banpick})`,
              }}
              onClick={() =>
                toggle_modal('banpick', true, 'pick', 'column_2-b')
              }>
              <img
                className="home__picked__wrapper__column__picked__ribbon"
                src={require('../assets/images/Number_placeholder.png')}
                alt="ribbon-number"
              />
              <span>7</span>
            </div>
          )}
        </div>

        <div className="home__picked__wrapper__column">
          <div className="home__picked__wrapper__column__icon">
            <img
              src={require(`../assets/images/icon-${mode.column_3}.png`)}
              alt="icon"
            />
          </div>
          <div
            className="home__picked__wrapper__column__picked"
            style={{
              backgroundImage: `url(${picked.column_3.teamA.img_banpick})`,
            }}
            onClick={() => toggle_modal('banpick', true, 'pick', 'column_3-a')}>
            <img
              className="home__picked__wrapper__column__picked__ribbon"
              src={require('../assets/images/Number_placeholder.png')}
              alt="ribbon-number"
            />
            <span>{bo === 3 ? 9 : 11}</span>
          </div>
          {bo === 3 ? (
            <div
              className="home__picked__wrapper__column__picked"
              style={{
                cursor: 'default',
              }}>
              <p>{team.teamA}</p>
            </div>
          ) : (
            <div
              className="home__picked__wrapper__column__picked"
              style={{
                background: 'none',
                border: 'none',
                cursor: 'default',
              }}>
              <p className="disable"></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Picked;

import axios from 'axios';

const cdn = axios.create({
  baseURL: `https://cdngarenanow-a.akamaihd.net/webid/Localize/${process.env.REACT_APP_LOCALIZE_NAME}/`,
  withCredentials: false,
  transformRequest: [
    (data, headers) => {
      delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME];
      return data;
    },
  ],
});

export const toggle_loader = (data) => ({
  type: 'TOGGLE_LOADER',
  data: data,
});

export const put_data = (key, data) => ({
  type: 'PUT_DATA',
  key,
  data,
});

export const set_banned = (data, variant, box) => ({
  type: 'BANNED',
  data,
  variant,
  box,
});

export const set_picked = (data, variant, box) => ({
  type: 'PICKED',
  data,
  variant,
  box,
});

export const set_mode = (data, variant) => ({
  type: 'MODE',
  data,
  variant,
});

export const get_map = () => {
  return (dispatch) => {
    cdn
      .get(`data.json?${Math.random() * 10000}`)
      .then((resp) => {
        dispatch(put_data('maps', resp.data.data));
      })
      .catch((err) => {});
  };
};

export const login = () => {
  return (dispatch) => {
    dispatch(toggle_loader(true));

    axios
      .get(process.env.REACT_APP_API_ENDPOINT)

      .then((resp) => {
        dispatch(put_data('login', resp.data));
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => {
        dispatch(toggle_loader(false));
      });
  };
};

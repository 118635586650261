export const getUrlParam = (pa) => {
  var url = window.location.href.replace(/#+.*$/),
    params = url.substring(url.indexOf('?') + 1, url.length).split('&'),
    param = {};

  for (var i = 0; i < params.length; i++) {
    var pos = params[i].indexOf('='),
      key = params[i].substring(0, pos),
      val = params[i].substring(pos + 1);

    param[key] = val;
  }

  return typeof param[pa] === 'undefined' ? false : param[pa];
};

export const removeParamUrl = () => {
  const location = window.location;
  window.history.pushState(
    {},
    document.title,
    location.origin + location.pathname
  );
};

export const err_msg = (err) => {
  if (err.response) {
    switch (err.response.status) {
      case 500:
        return 'Please Refresh Your Browser!';
      default:
        return err.response.data.msg;
    }
  } else if (err.request) {
    return 'Please Refresh Your Browser!';
  } else {
    return 'Please Refresh Your Browser!';
  }
};

export const sortMaps = (array) => {
  return [...array].sort((a, b) => a.name.localeCompare(b.name));
};

export const renderVariant = (variant) => {
  if (variant === 'column_1-a' || variant === 'column_1-b') {
    return 'column_1';
  } else if (variant === 'column_2-a' || variant === 'column_2-b') {
    return 'column_2';
  } else {
    return 'column_3';
  }
};

export const renderBox = (box) => {
  if (box === 'column_1-a' || box === 'column_2-a' || box === 'column_3-a') {
    return 'teamA';
  } else {
    return 'teamB';
  }
};

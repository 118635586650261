export const toggle_modal = (modal, toggle, variant, box) => ({
  type: 'TOGGLE_MODAL',
  modal,
  toggle,
  variant,
  box
});

export const set_msg = (title, msg) => ({
  type: 'SET_MSG',
  title,
  msg,
});

import React from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { sortMaps } from '../../../helper';

const BanPick = (props) => {
  const handleClick = (data) => {
    if (variant === 'ban') {
      set_banned(data, variant, box);
    } else {
      set_picked(data, variant, box);
    }
    toggle_modal('banpick', false);
  };

  const { maps, disable_maps, banned, picked } = props.main;
  const { banpick, variant, box } = props.modal;
  const { set_banned, set_picked } = props.actionsMain;
  const { toggle_modal } = props.actionsModal;
  const mapsAvailable = maps.filter((ele) => !disable_maps.includes(ele));
  let mapsBanned;

  if (box === 'column_1-a' || box === 'column_1-b') {
    mapsBanned = maps.filter(
      (ele) =>
        ele === banned.column_1.teamA ||
        ele === banned.column_1.teamB ||
        ele === picked.column_1.teamA ||
        ele === picked.column_1.teamB
    );
  } else if (box === 'column_2-a' || box === 'column_2-b') {
    mapsBanned = maps.filter(
      (ele) =>
        ele === banned.column_2.teamA ||
        ele === banned.column_2.teamB ||
        ele === picked.column_2.teamA ||
        ele === picked.column_2.teamB
    );
  } else {
    mapsBanned = maps.filter(
      (ele) =>
        ele === banned.column_3.teamA ||
        ele === banned.column_3.teamB ||
        ele === picked.column_3.teamA ||
        ele === picked.column_3.teamB
    );
  }
  const sortedMaps = sortMaps(mapsAvailable);

  return (
    <Modal
      open={banpick}
      onClose={() => toggle_modal('banpick', false)}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: 'modal modal_banpick',
      }}>
      <div className="title">
        <div
          className="btn-close"
          onClick={() => toggle_modal('banpick', false)}>
          <img
            src={`https://cdngarenanow-a.akamaihd.net/webid/AOV/changefate/btn_Close.png`}
            alt=""
          />
        </div>
        <span>{`${variant ? `${variant} MAP` : ''}`}</span>
      </div>
      <div className="wrapper">
        <div className="banpick">
          <div className="banpick__list">
            {sortedMaps.map((ele, i) => {
              return (
                <div
                  key={i}
                  className={`banpick__list__item ${
                    mapsBanned.includes(ele) ? 'disable' : ''
                  }`}>
                  <img
                    src={ele.img_banpick}
                    alt="map"
                    onClick={() =>
                      !mapsBanned.includes(ele) && handleClick(ele)
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BanPick;

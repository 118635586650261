import React, { useState } from 'react';

const Banned = (props) => {
  const [state, setstate] = useState({
    teamA: 'TEAM A',
    teamB: 'TEAM B',
  });

  const handleText = (e, team) => {
    if (team === 'teamA') {
      setstate({
        ...state,
        teamA: e.target.value,
      });
    } else {
      setstate({
        ...state,
        teamB: e.target.value,
      });
    }
  };

  const { banned, mode, bo } = props.main;
  const { put_data } = props.actionsMain;
  const { toggle_modal } = props.actionsModal;

  return (
    <div className="home__banned">
      <div className="home__banned__title">
        <img src={require('../assets/images/banned.png')} alt="banned" />
      </div>
      <div className="home__banned__team">
        <input
          value={state.teamA}
          type="text"
          onChange={(e) => handleText(e, 'teamA')}
          onBlur={() => put_data('team', state)}
        />
        <input
          value={state.teamB}
          type="text"
          onChange={(e) => handleText(e, 'teamB')}
          onBlur={() => put_data('team', state)}
        />
      </div>

      {/* COLUMN 1 */}
      <div className="home__banned__wrapper">
        <div className="home__banned__wrapper__column">
          <div
            className="home__banned__wrapper__column__banned"
            style={{
              backgroundImage: `url(${banned.column_1.teamA.img_banpick})`,
            }}
            onClick={() => {
              toggle_modal('banpick', true, 'ban', 'column_1-a');
            }}>
            <img
              src={require('../assets/images/Number_placeholder.png')}
              alt="ribbon-number"
            />
            <p>1</p>
          </div>
          <div
            className="home__banned__wrapper__column__banned"
            style={{
              backgroundImage: `url(${banned.column_1.teamB.img_banpick})`,
            }}
            onClick={() => {
              toggle_modal('banpick', true, 'ban', 'column_1-b');
            }}>
            <img
              src={require('../assets/images/Number_placeholder.png')}
              alt="ribbon-number"
            />
            <p>2</p>
          </div>
          <div
            className="home__banned__wrapper__column__icon"
            onClick={() => toggle_modal('switch_mode', true, 'column_1')}>
            <img
              src={require(`../assets/images/icon-${mode.column_1}.png`)}
              alt="icon"
            />
            <img
              src={require('../assets/images/Icon_Changemode.png')}
              alt="change"
            />
          </div>
        </div>

        {/* COLUMN 2 */}
        <div className="home__banned__wrapper__column">
          <div
            className="home__banned__wrapper__column__banned"
            style={{
              backgroundImage: `url(${banned.column_2.teamA.img_banpick})`,
            }}
            onClick={() => {
              toggle_modal('banpick', true, 'ban', 'column_2-a');
            }}>
            <img
              src={require('../assets/images/Number_placeholder.png')}
              alt="ribbon-number"
            />
            <p>{bo === 3 ? 5 : 6}</p>
          </div>
          <div
            className="home__banned__wrapper__column__banned"
            style={{
              backgroundImage: `url(${banned.column_2.teamB.img_banpick})`,
            }}
            onClick={() => {
              toggle_modal('banpick', true, 'ban', 'column_2-b');
            }}>
            <img
              src={require('../assets/images/Number_placeholder.png')}
              alt="ribbon-number"
            />
            <p>{bo === 3 ? 4 : 5}</p>
          </div>
          <div
            className="home__banned__wrapper__column__icon"
            onClick={() => toggle_modal('switch_mode', true, 'column_2')}>
            <img
              src={require(`../assets/images/icon-${mode.column_2}.png`)}
              alt="icon"
            />
            <img
              src={require('../assets/images/Icon_Changemode.png')}
              alt="change"
            />
          </div>
        </div>

        {/* COLUMN 3 */}
        <div className="home__banned__wrapper__column">
          <div
            className="home__banned__wrapper__column__banned"
            style={{
              backgroundImage: `url(${banned.column_3.teamA.img_banpick})`,
            }}
            onClick={() => {
              toggle_modal('banpick', true, 'ban', 'column_3-a');
            }}>
            <img
              src={require('../assets/images/Number_placeholder.png')}
              alt="ribbon-number"
            />
            <p>{bo === 3 ? 7 : 9}</p>
          </div>
          <div
            className="home__banned__wrapper__column__banned"
            style={{
              backgroundImage: `url(${banned.column_3.teamB.img_banpick})`,
            }}
            onClick={() => {
              toggle_modal('banpick', true, 'ban', 'column_3-b');
            }}>
            <img
              src={require('../assets/images/Number_placeholder.png')}
              alt="ribbon-number"
            />
            <p>{bo === 3 ? 8 : 10}</p>
          </div>
          <div
            className="home__banned__wrapper__column__icon"
            onClick={() => toggle_modal('switch_mode', true, 'column_3')}>
            <img
              src={require(`../assets/images/icon-${mode.column_3}.png`)}
              alt="icon"
            />
            <img
              src={require('../assets/images/Icon_Changemode.png')}
              alt="change"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banned;

import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';

// Component
import ViewportRestrict from 'helper/component/viewport';
import Loader from 'helper/component/loader';
import NotFound from 'helper/component/404';
import ModalCont from '../partial/Modal';

// Pages
import Home from 'pages/Home.js';
import ErrorBoundary from 'ErrorBoundary';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';
import * as modalActions from 'redux/actions/modal';

class Main extends Component {
  componentDidMount() {
    this.props.actionsMain.get_map();
  }
  render() {
    return (
      <Router basename={process.env.REACT_APP_BASENAME}>
        <div id="Main" className="main-panel">
          <ErrorBoundary>
            <ViewportRestrict display={true} type="portrait" />
            <Loader loader={false} />
            <div className="content-container">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route component={NotFound} />
              </Switch>
            </div>
            <ModalCont {...this.props} />
          </ErrorBoundary>
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    main: state.main,
    modal: state.modal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actionsMain: bindActionCreators(mainActions, dispatch),
    actionsModal: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);

import React, { useState } from 'react';
import $ from 'jquery';

const Coin = () => {
  const [state, setstate] = useState({
    flip: '',
  });

  const flipCoin = () => {
    const flipResult = Math.floor(Math.random() * 2);
    let firstPickTeam = '';
    setstate({
      ...state,
      flip: firstPickTeam,
    });
    $('#coin').removeClass();
    setTimeout(() => {
      if (flipResult === 0) {
        $('#coin').addClass('side-a');
        firstPickTeam = 'LOGO M';
      } else {
        $('#coin').addClass('side-b');
        firstPickTeam = 'GHOST';
      }
    }, 1);
    setTimeout(() => {
      setstate({
        ...state,
        flip: firstPickTeam,
      });
    }, 3000);
  };

  return (
    <>
      <div id="coin" onClick={() => flipCoin()}>
        <div className="side-a"></div>
        <div className="side-b"></div>
      </div>
      <h3>{state.flip}</h3>
    </>
  );
};

export default Coin;

import React from 'react';
import Coin from './Coin';

const Bottom = (props) => {
  const { bo, isConfirm } = props.main;
  const { put_data } = props.actionsMain;

  return (
    <div className={`home__bottom ${isConfirm ? 'confirm' : ''}`}>
      {!isConfirm ? (
        <>
          <div className="home__bottom__coin">
            <Coin />
          </div>
          <div className="home__bottom__mode">
            <div className="home__bottom__mode__top">
              <p>GAME MODE</p>
              <img
                className={bo !== 3 ? 'disable' : ''}
                src={require('../assets/images/BO3.png')}
                alt="bo3"
                onClick={() => put_data('bo', 3)}
              />
              <img
                className={bo !== 5 ? 'disable' : ''}
                src={require('../assets/images/BO5.png')}
                alt="bo5"
                onClick={() => put_data('bo', 5)}
              />
            </div>
            <div className="home__bottom__mode__bot">
              <div
                className="home__bottom__mode__bot__button"
                onClick={() => put_data('isConfirm', true)}>
                .
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="wrapper">
          <p>Pilih map yang tidak ingin dimainkan di event kali ini</p>
          <div className="button" onClick={() => put_data('isConfirm', false)}>
            .
          </div>
        </div>
      )}
    </div>
  );
};

export default Bottom;

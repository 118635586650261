import React from 'react';

// Component
import BanPick from './component/BanPick';
import SwitchMode from './component/SwitchMode';

const ModalCont = (props) => {
  return (
    <>
      <BanPick {...props} />
      <SwitchMode {...props} />
    </>
  );
};

export default ModalCont;

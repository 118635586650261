import React from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const SwitchMode = (props) => {
  const { mode } = props.main;
  const { switch_mode, variant } = props.modal;
  const { set_mode } = props.actionsMain;
  const { toggle_modal } = props.actionsModal;

  const getMode = () => {
    switch (variant) {
      case 'column_1':
        return mode.column_1;
      case 'column_2':
        return mode.column_2;
      case 'column_3':
        return mode.column_3;
      default:
        break;
    }
  };

  return (
    <Modal
      open={switch_mode}
      onClose={() => toggle_modal('switch_mode', false)}
      focusTrapped={false}
      center
      showCloseIcon={false}
      closeOnOverlayClick={false}
      classNames={{
        overlay: 'overlay',
        modal: 'modal modal_switch_mode',
      }}>
      <div className="title">
        <div
          className="btn-close"
          onClick={() => toggle_modal('switch_mode', false)}>
          <img
            src={`https://cdngarenanow-a.akamaihd.net/webid/AOV/changefate/btn_Close.png`}
            alt=""
          />
        </div>
        <span>PILIH MODE MULTIPLAYER</span>
      </div>
      <div className="wrapper">
        <div className="switch_mode">
          <div className="switch_mode__list">
            <div
              className={`switch_mode__list__item ${
                getMode() === 'bm' ? 'disable' : ''
              }`}
              onClick={() => {
                if (getMode() !== 'bm') {
                  set_mode('bm', variant);
                  toggle_modal('switch_mode', false);
                }
              }}>
              <img
                src={require('../../../assets/images/icon-bm.png')}
                alt="icon"
              />
            </div>
            <div
              className={`switch_mode__list__item ${
                getMode() === 'hp' ? 'disable' : ''
              }`}
              onClick={() => {
                if (getMode() !== 'hp') {
                  set_mode('hp', variant);
                  toggle_modal('switch_mode', false);
                }
              }}>
              <img
                src={require('../../../assets/images/icon-hp.png')}
                alt="icon"
              />
            </div>
            <div
              className={`switch_mode__list__item ${
                getMode() === 'dom' ? 'disable' : ''
              }`}
              onClick={() => {
                if (getMode() !== 'dom') {
                  set_mode('dom', variant);
                  toggle_modal('switch_mode', false);
                }
              }}>
              <img
                src={require('../../../assets/images/icon-dom.png')}
                alt="icon"
              />
            </div>
            <div
              className={`switch_mode__list__item ${
                getMode() === 'tdm' ? 'disable' : ''
              }`}
              onClick={() => {
                if (getMode() !== 'tdm') {
                  set_mode('tdm', variant);
                  toggle_modal('switch_mode', false);
                }
              }}>
              <img
                src={require('../../../assets/images/icon-tdm.png')}
                alt="icon"
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SwitchMode;

import { renderVariant, renderBox } from '../../helper';

const main_state = {
  login: false,
  loader: false,
  maps: [],
  disable_maps: [],
  team: {
    teamA: 'TEAM A',
    teamB: 'TEAM B',
  },
  bo: 3,
  mode: {
    column_1: 'bm',
    column_2: 'hp',
    column_3: 'dom',
  },
  banned: {
    column_1: {
      teamA: false,
      teamB: false,
    },
    column_2: {
      teamA: false,
      teamB: false,
    },
    column_3: {
      teamA: false,
      teamB: false,
    },
  },
  picked: {
    column_1: {
      teamA: false,
      teamB: false,
    },
    column_2: {
      teamA: false,
      teamB: false,
    },
    column_3: {
      teamA: false,
    },
  },
  isConfirm: false,
};

const main = (state = main_state, action) => {
  switch (action.type) {
    case 'PUT_DATA':
      return { ...state, [action.key]: action.data };
    case 'BANNED':
      return {
        ...state,
        banned: {
          ...state.banned,
          [`${renderVariant(action.box)}`]: {
            ...(action.box === 'column_1-a' || action.box === 'column_1-b'
              ? state.banned.column_1
              : action.box === 'column_2-a' || action.box === 'column_2-b'
              ? state.banned.column_2
              : state.banned.column_3),
            [`${renderBox(action.box)}`]: action.data,
          },
        },
      };
    case 'PICKED':
      return {
        ...state,
        picked: {
          ...state.picked,
          [`${renderVariant(action.box)}`]: {
            ...(action.box === 'column_1-a' || action.box === 'column_1-b'
              ? state.picked.column_1
              : action.box === 'column_2-a' || action.box === 'column_2-b'
              ? state.picked.column_2
              : state.picked.column_3),
            [`${renderBox(action.box)}`]: action.data,
          },
        },
      };
    case 'MODE':
      return {
        ...state,
        mode: {
          ...state.mode,
          [action.variant]: action.data,
        },
      };
    case 'TOGGLE_LOADER':
      return { ...state, loader: action.data };
    default:
      return state;
  }
};

export default main;

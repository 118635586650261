const modal_state = {
  msg: {
    title: false,
    message: false,
  },
  banpick: false,
  switch_mode: false,
  variant: false,
  box: false,
};

const modal = (state = modal_state, action) => {
  switch (action.type) {
    case 'TOGGLE_MODAL':
      return {
        ...state,
        [action.modal]: action.toggle,
        variant: action.variant,
        box: action.box,
      };
    case 'SET_MSG':
      return {
        ...state,
        msg: {
          title: action.title,
          message: action.msg,
        },
      };
    default:
      return state;
  }
};

export default modal;

import React from 'react';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as mainActions from 'redux/actions/main';
import * as modalActions from 'redux/actions/modal';

import Banned from '../partial/Banned';
import Picked from '../partial/Picked';
import Bottom from '../partial/Bottom';
import { sortMaps } from '../helper';

const Home = (props) => {
  const handleClick = (ele, action) => {
    if (action === 'disable') {
      const disable = [...disable_maps];
      disable.push(ele);
      put_data('disable_maps', disable);
    } else if (action === 'enable') {
      const disable = [...disable_maps];
      const removeEle = disable.filter((item) => item !== ele);
      put_data('disable_maps', removeEle);
    }
  };

  const { maps, disable_maps, isConfirm } = props.main;
  const { put_data } = props.actionsMain;
  const sortedMaps = sortMaps(maps);

  return (
    <div className="home">
      <div className="home__maplist">
        <div className="home__maplist__wrapper">
          {sortedMaps.map((ele, i) => (
            <div key={i} className="home__maplist__wrapper__list">
              <img
                src={ele.img}
                alt="map"
                onClick={() => isConfirm && handleClick(ele, 'disable')}
              />
              {disable_maps
                .filter((item) => item === ele)
                .map((ele, i) => (
                  <img
                    key={i}
                    src={require('../assets/images/Locked_Map.png')}
                    alt="locked"
                    onClick={() => isConfirm && handleClick(ele, 'enable')}
                  />
                ))}
            </div>
          ))}
        </div>
      </div>
      <Banned {...props} />
      <Picked {...props} />
      <Bottom {...props} />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    main: state.main,
    modal: state.modal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actionsMain: bindActionCreators(mainActions, dispatch),
    actionsModal: bindActionCreators(modalActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
